(function () {
'use strict';

angular.module('aerosApp').
factory('TestSetupResource', TestSetupResource).
factory('OLTSLibResource', OLTSLibResource).
factory('CertificationLibResource', CertificationLibResource).
factory('InspectionLibResource', InspectionLibResource);

TestSetupResource.$inject = ['$resource', 'ToolsDefinitionService', 'CommonService', '$stateParams'];
function TestSetupResource ($resource, ToolsDefinitionService, CommonService, $stateParams) {
  var baseUrl = CommonService.baseUrl + '/organization/:orgId';
  var format = ToolsDefinitionService.format;

  return function (params, methods) {
    var resource = $resource(baseUrl + '/:toolId',
      {
        orgId     : '@orgId',
        toolType  : '@toolType',
        toolId    : '@toolId'
      }, _.defaultsDeep(methods, {
        query     : {
          url: baseUrl + '/alltestSetups',
          isArray: true,
          interceptor: {response: intercept}
        },
        create    : {
          url: baseUrl + '/createTestSetup/:toolType',
          method: 'POST',
          params: {toolType: params.toolType},
          transformRequest: transformCreateRequest
        },
        delete    : {
          url: baseUrl + '/:toolType/tools/:key',
          params: {
            toolType: params.toolType.toLowerCase(),
            key: params.key
          },
          method: 'DELETE'
        }
      }));

    var TestSetupPrototype = TestSetupModelPrototype(resource);
    var modelize = format.modelizeItem(TestSetupPrototype);
    return resource;

    function transformCreateRequest (data) {
      return angular.toJson({
        testSetup: data
      });
    }

    function intercept (setups) {
      return _.chain(setups.data).
        filter(filterBy(params.toolType)).
        map(addToolProps).
        // filter(_.matches({active:true})).
        map(modelize).
        value();
    }

    function filterBy (toolType) {
      return function (setup) {
        return toolType.toLowerCase() === setup.type.toLowerCase();
      }
    }

    // TODO:
    // this is bad thing that the service relies on shared state
    // and can be affected by params renaming, etc...
    // remove this as soon as server returns org id within an item
    function addToolProps (item) {
      return _.defaults(item, {
        toolType: params.toolType,
        active: !item.archived
      });
    }

    function TestSetupModelPrototype (resource) {
      return {
        deactivate: deactivate,
        denyView: true
      };

      function deactivate () {
        return resource.
          delete({ orgId: $stateParams.id, key: this.key}).$promise;
      }
    }

  }
}

OLTSLibResource.$inject = ['TestSetupResource']
function OLTSLibResource (TestSetupResource) {
  return TestSetupResource({toolType: 'OLTS'});
}

CertificationLibResource.$inject = ['TestSetupResource']
function CertificationLibResource (TestSetupResource) {
  return TestSetupResource({toolType: 'Certification'});
}

InspectionLibResource.$inject = ['TestSetupResource']
function InspectionLibResource (TestSetupResource) {
  return TestSetupResource({toolType: 'Inspection'});
}

}());
